<template>
  <a-layout-content
    :style="{
      margin: '24px 16px',
      padding: this.$route.name === 'orderDetail' ? '' : '24px',
      background: '#fff',
      minHeight: '280px',
      overflow: 'auto',
    }"
  >
    <router-view />
  </a-layout-content>
</template>
<script>
export default {
  name: "Content",
  data() {
    return {
      collapsed: false,
    };
  },
  computed: {},
  methods: {},
};
</script>
<style>
</style>