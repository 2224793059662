/**
 * axios实例封装，在这里加上项目对接口请求的统一处理
 */
import axios from "axios";
import config from "./config";
// import router from '@/router'
import message from "ant-design-vue/es/message";

const { apiHost } = config;

const option = {
  baseURL: process.env.NODE_ENV === "production" ? apiHost : "",
  timeout: 20000, // 请求超时时间
  // headers: {
  //   // 'Content-Type': 'application/vnd.ms-excel;charset=utf-8'
  // 	// 'Content-Type': "multipart/form-data",
  // }
};
// 创建 axios 实例Authorization:
const service = axios.create(option);
service.interceptors.request.use(
  (config) => {
    config.headers.Authorization = sessionStorage.getItem('token')
      // store.state.token === ""
      //   ? "Bearer 6cd21b68fcc53a11b6b2ee0b972c3734"
      //   : "Bearer " + store.state.token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    // 判断处理结果是文件类型时，不对结果集处理，直接返回
    if (res instanceof Blob) {
      return response;
    }
    // 这里需要根据不同的项目后端接口封装情况做适当调整
    if (res.isError) {
      message.error((res && res.message) || "未知异常！");
      return Promise.reject(new Error((res && res.message) || "未知异常！"));
    } else {
      return res;
    }
  },
  (error) => {
    if (error?.response?.status === 401) {
      sessionStorage.clear();
      location.href = window.location.origin+"/login";
    }
    if (axios.isCancel(error)) {
      return null;
    }
    return Promise.reject(error);
  }
);

export default service;
