// 本地运行的
// const apiHost_D = 'http://192.168.233.42:8004' 
const apiHost_D = 'http://192.168.233.181:32333' 

// 线上部署的
const apiHost_P = ''
const config = {
  apiHost : process.env.NODE_ENV === "production" ? apiHost_P: apiHost_D ,
  loginPath: '', // 登陆地址

}


export default config