import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout";
import Login from "@/views/login";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Layout,
    redirect: "/contentManage",
    children: [
      {
        path: "/contentManage",
        name: "contentManage",
        meta: {
          title: "内容维护",
        },
        component: () =>
          import("../views/contentManage/contentManage/index.vue"),
      },
      {
        path: "/leaveMessage",
        name: "leaveMessage",
        meta: {
          title: "用户留言",
        },
        component: () => import("../views/leaveMessage/index.vue"),
      },

      {
        path: "/businessReview",
        name: "businessReview",
        meta: {
          title: "企业账户审核",
        },
        component: () => import("../views/businessReview/index.vue"),
      },
      {
        path: "/personDeveloper",
        name: "personDeveloper",
        meta: {
          title: "个人开发者审核",
        },
        component: () => import("../views/personDeveloper/index.vue"),
      },
      {
        path: "/businessDeveloper",
        name: "businessDeveloper",
        meta: {
          title: "企业开发者审核",
        },
        component: () => import("../views/businessDeveloper/index.vue"),
      },
      {
        path: "/accountManage",
        name: "accountManage",
        meta: {
          title: "账号管理",
        },
        component: () => import("../views/accountManage/index.vue"),
      },
      {
        path: "/orderManage",
        name: "orderManage",
        meta: {
          title: "订单管理",
        },
        component: () => import("../views/orderManage/index.vue"),
      },
      {
        path: "/orderDetail/:id",
        name: "orderDetail",
        meta: {
          title: "订单详情",
        },
        component: () => import("../views/orderManage/orderDetail/index.vue"),
      },
      {
        path: "/sassTenant",
        name: "sassTenant",
        meta: {
          title: "厘米OS租户",
        },
        component: () => import("../views/sassTenant/index.vue"),
      },{
        path: "/sassGrade",
        name: "sassGrade",
        meta: {
          title: "厘米OS等级",
        },
        component: () => import("../views/sassGrade/index.vue"),
      },{
        path: "/sassResource",
        name: "sassResource",
        meta: {
          title: "厘米OS资源",
        },
        component: () => import("../views/sassResource/index.vue"),
      },{
        path: "/sassLogicalResource",
        name: "sassLogicalResource",
        meta: {
          title: "厘米OS逻辑资源",
        },
        component: () => import("../views/sassLogicalResource/index.vue"),
      },{
        path: "/sassInfo",
        name: "sassInfo",
        meta: {
          title: "租户应用资源",
        },
        component: () => import("../views/sassInfo/index.vue"),
      },{
        path: "/appCheck",
        name: "appCheck",
        meta: {
          title: "应用审核管理",
        },
        component: () => import("../views/appCheck/index.vue"),
      },{
        path: "/appCheck/detail",
        name: "appDetail",
        meta: {
          title: "应用详情",
        },
        component: () => import("../views/appCheck/detail.vue"),
      },{
        path: "/solutionCheck",
        name: "solutionCheck",
        meta: {
          title: "解决方案审核管理",
        },
        component: () => import("../views/solutionCheck/index.vue"),
      },{
        path: "/solutionCheck/detail",
        name: "solutionDetail",
        meta: {
          title: "解决方案详情",
        },
        component: () => import("../views/solutionCheck/detail.vue"),
      },
    ],
  },

  {
    path: "/login",
    name: "login",
    meta: {
      title: "登录",
    },
    component: Login,
  },
];
const router = new VueRouter({
  mode: "history",
  
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.name !== "login") {
    const access_token = sessionStorage.getItem("token");
    if (access_token) {
      next();
    } else {
      router.push({ name: "login" });
    }
  }
  next();
});

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
