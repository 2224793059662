<template>
  <a-layout class="container" id="components-layout-demo-custom-trigger">
    <sidebars :menuData="menuData"> </sidebars>
    <a-layout>
      <headers></headers>
      <contents></contents>
    </a-layout>
  </a-layout>
</template>
<script>
import Sidebars from "./Siderbar.vue";
import Headers from "./Header.vue";
import Contents from "./Content.vue";
export default {
  components: {
    Sidebars,
    Headers,
    Contents,
  },
  data() {
    return {
      collapsed: false,
      menuData: [
        { title: "内容维护", key: "/contentManage", path: "/contentManage" },
        { title: "用户留言", key: "/leaveMessage", path: "/leaveMessage" },
        { title: "账号管理", key: "/accountManage", path: "/accountManage" },
        {
          title: "企业账户审核",
          key: "/businessReview",
          path: "/businessReview",
        },

        {
          title: "开发者审核",
          key: "5",
          children: [
            {
              title: "个人开发者审核",
              path: "/personDeveloper",
              key: "/personDeveloper",
            },
            {
              title: "企业开发者审核",
              path: "/businessDeveloper",
              key: "/businessDeveloper",
            },
          ],
        },
        {
          title: "订单管理",
          key: "/orderManage",
          path: "/orderManage",
          // children: [
          //   {
          //     title: "订单管理",
          //     path: "/orderManage",
          //     key: "6-1",
          //   },
          //   {
          //     title: "订单详情",
          //     path: "/orderDetail",
          //     key: "6-2",
          //   },
          // ],
        },
        { title: "厘米OS租户", key: "/sassTenant", path: "/sassTenant" },
        { title: "厘米OS等级", key: "/sassGrade", path: "/sassGrade" },
        {
          title: "厘米OS资源管理",
          key: "/sassResource",
          path: "/sassResource",
        },
          {
          title: "厘米OS逻辑资源管理",
          key: "/sassLogicalResource",
          path: "/sassLogicalResource",
        }, {
          title: "租户应用资源",
          key: "/sassInfo",
          path: "/sassInfo",
        },{
          title: "应用审核管理",
          key: "/appCheck",
          path: "/appCheck",
        },{
          title: "解决方案审核管理",
          key: "/solutionCheck",
          path: "/solutionCheck",
        },
        // { title: "厘米OS租户", key: "/accountManage", path: "/accountManage" },
        // { title: "厘米OS租户", key: "/accountManage", path: "/accountManage" },
        // { title: "企业账户审核", path: "/businessReview" },
      ],
    };
  },
};
</script>
<style>
.container {
  height: 100vh;
}
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
</style>