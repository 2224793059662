<template>
  <div>
    <div class="header_contain">
      <div class="header_inner">
        <img class="logo_img" src="../../assets/logos.png" alt="" />
        <span class="logo_text">工业互联网平台后台</span>
      </div>
    </div>
    <div class="contain">
      <div class="contain_right">
        <div class="contain_top">用户登录</div>
        <div class="contain_form">
          <a-form
            id="components-form-demo-normal-login"
            :form="form"
            class="login-form"
            @submit="handleSubmit"
          >
            <a-form-item>
              <a-input
                v-decorator="[
                  'username',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入您的账户名!',
                      },
                    ],
                  },
                ]"
                placeholder="账户"
              >
                <a-icon slot="prefix" type="user" style="color: #1890ff" />
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-input
                v-decorator="[
                  'password',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入您的密码!',
                      },
                    ],
                  },
                ]"
                type="password"
                placeholder="密码"
              >
                <a-icon slot="prefix" type="lock" style="color: #1890ff" />
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-button
                type="primary"
                html-type="submit"
                class="login-form-button"
                :loading="loading"
              >
                登录
              </a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </div>
    <div style="padding-top: 40px; padding-bottom: 40px">
      <div class="footer_text">
        Copyright © http://www.nbict.ac.cn, All Rights Reserved.
      </div>
      <div class="footer_text">宁波中国科学院信息技术应用研究院</div>
    </div>
  </div>
</template>

<script>
import { userApi } from "@/api/user.js";
export default {
  name: "login",

  data() {
    return {
      loading:false
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.login(values);
        }
      });
    },
    login(params) {
      this.loading=true
      userApi.login(params).then((res) => {
        if (res.success) {
           this.loading=false
          window.sessionStorage.setItem("token", res.data.token);
          this.$store.commit("SetName", res.data.username);
          this.$router.push("/");
        } else {
            this.loading=false
          this.$message.error(res.errorMsg);
        }
      });
    },
  },
  mounted() {},

  watch: {},
};
</script>

<style lang="scss" scoped>
.contain_right {
  height: 350px;
  width: 410px;
  background-color: white;
  margin-left: 60%;
}
.header_inner {
  width: 300px;
  margin-left: 20%;
  height: 92px;
  line-height: 92px;
}
.contain {
  background-image: url("../../assets/bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 74vh;
  width: 100%;
  display: flex;
  align-items: center;
}
.contain_form {
  width: 80%;
  margin: auto;
}
.contain_top {
  height: 90px;
  width: 100%;
  line-height: 90px;
  text-align: center;
  font-size: 22px;
}
.header_contain {
  height: 92px;
}
.logo_img {
  width: 66px;
  margin-right: 20px;
}
.logo_text {
  font-size: 20px;
  font-weight: 900;
}
#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
  font-weight: 900;
}
.footer_text {
  height: 22px;
  font-size: 12px;

  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: #999999;
}
</style>