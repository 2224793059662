<template>
  <a-layout-header style="background: #fff; padding: 0">
    <div class="header">
      <div>
        <!-- <a-icon
          class="trigger"
          :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          @click="collapsedChange"
        /> -->
      </div>
      <div class="header_right">
        <a-popover placement="bottom">
          <template slot="content">
            <div @click="loginOut" class="popover_contain">登出</div>
          </template>
          <div class="headImage">{{ firstName }}</div>
        </a-popover>
        <div style="margin-left: 10px">{{ username }}</div>
      </div>
    </div>
  </a-layout-header>
</template>
<script>
import { userApi } from "@/api/user.js";
import { mapState } from "vuex";

export default {
  name: "Header",
  data() {
    return {
      collapsed: false,
      firstName: "",
    };
  },
  computed: {
    ...mapState(["username"]),
  },
  created() {
    this.getFisrtName();
  },
  methods: {
    loginOut() {
      userApi.logout().then((res) => {
        if (res.success) {
          // sessionStorage.removeItem("token");
           sessionStorage.clear()
          this.$router.push("/login");
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    getFisrtName() {
      this.firstName = this.username.slice(0, 1);
    },
    collapsedChange() {
      this.collapsed = !this.collapsed;
      this.$store.commit("SetCollapsed", this.collapsed);
    },
  },
};
</script>
<style  lang="scss" scoped>
.popover_contain {
  height: 30px;
  width: 80px;
  // background-color: aqua;
  cursor: pointer;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
}
.popover_contain:hover {
  background-color: #f3f3f3;
}
.headImage {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  background-color: #a6dbff;
  margin-top: 14px;
  line-height: 34px;
  text-align: center;
  color: #fff;
}
.header_right {
  display: flex;
  /* background-color: cadetblue; */
  justify-content: space-between;
}
.header {
  /* background-color: aqua; */
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}
.logoContainer {
  height: 40px;
  width: 200px;
  background-color: aqua;
}
</style>