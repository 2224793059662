import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    username:"",
    collapsed:true
  },
  mutations: {
    SetName(state,payload){
      state.username = payload
  },
  SetCollapsed(state,payload){
    state.collapsed = payload
}
  },
  actions: {
  },
  modules: {
  }
})
