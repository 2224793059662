import axios from "@/config/http";
// import qs from 'qs'
export const userApi = {
  // 获取table数据
  login(params) {
    return axios.post(
      "/cloud-platform-backend-facade/front-api/v1/user/backend/auth/login",
      params
    );
  },
  logout() {
    return axios.get(
      "/cloud-platform-backend-facade/front-api/v1/user/backend/auth/logout",
    );
  },
};
