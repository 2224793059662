<template>
  <a-layout-sider v-model="collapsed" :trigger="null" collapsible>
    <div class="logo">
      <img src="../assets/logos.png" style="width: 30px" alt="" />
      <span style="margin-left: 10px">互联网云平台后台</span>
    </div>
    <a-menu theme="dark" mode="inline" :default-selected-keys="[$route.path]">
      <template v-for="item in menuData">
        <a-menu-item
          @click="handleClick(item)"
          v-if="!item.children"
          :key="item.key"
        >
          <!-- <a-icon type="pie-chart" /> -->
          <span>{{ item.title }}</span>
        </a-menu-item>
        <a-sub-menu v-else :key="item.key">
          <span slot="title">
            <!-- <a-icon type="pie-chart" /> -->
            <span>{{ item.title }}</span></span
          >
          <a-menu-item
            @click="handleClick(ichildren)"
            v-for="ichildren in item.children"
            :key="ichildren.key"
          >
            <!-- <a-icon type="pie-chart" /> -->
            <span>{{ ichildren.title }}</span>
          </a-menu-item>
        </a-sub-menu>
      </template>
    </a-menu>
  </a-layout-sider>
</template>
<script>
// import { mapState } from "vuex";

export default {
  name: "Siderbar",
  props: {
    menuData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      collapsed: false,
    };
  },
  computed: {
    // ...mapState(["collapsed"]),
  },
  methods: {
    handleClick(item) {
      this.$router.push(item.path);
    },
  },
};
</script>
<style>
/* .container{
    height: 100vh;
} */
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(160, 57, 57, 0) !important;
  margin: 16px;
  line-height: 32px;
  color: #fff;
}
</style>