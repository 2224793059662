import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Viewer.setDefaults({
  toolbar: {
    zoomIn: true,
    zoomOut: true,
    oneToOne: true,
    reset: false,
    prev: true,
    play: {
      show: true,
      size: 'large'
    },
    next: true,
    rotateLeft: true,
    rotateRight: true,
    flipHorizontal: false,
    flipVertical: false
  }
})

Vue.config.productionTip = false
Vue.use(Antd);
Vue.use(Viewer)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
